import React from "react";
import { Container } from "@mui/material";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg">
      <PrivacyPolicyContent />
    </Container>
  );
};

export default PrivacyPolicy;
