import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { Grid, Container } from "@mui/material";

import * as actions from "../../../store/actions/index";
import axios from "../../../axiosInstance";
import Button from "../Button/Button";
import Spinner from "../Loading/Spinner";
import TextField from "../FormFields/TextField";
import ColorPicker from "../FormFields/ColorPicker";
import DatePicker from "../FormFields/DatePicker";
import Autocomplete from "../FormFields/Autocomplete";
import ChainedAutocomplete from "../FormFields/ChainedAutocomplete";
import Checkbox from "../FormFields/Checkbox";
import ImageUpload from "../FormFields/ImageUpload";
import FileUpload from "../FormFields/FileUpload";
import ImageUploadBase64 from "../FormFields/ImageUploadBase64";

const Form = ({
  //from component call
  fieldMap,
  url,
  redirectUrl,
  linkedData,
  masterName,
  watchValues,
  chainedFields,
  formName,
  resetChildChainField,
  customSubmitHandler, //optional prop to handle submit, if not given uses default

  //from redux store
  chainedData,
  formSupplementaryData,
  onUpdateChainedData,
  setDataReadyAfterUpdate,
  resetDataReadyAfterUpdate,
  ...props
}) => {
  const [formData, setFormData] = useState(null);
  const [formStatus, setFormStatus] = useState(null);

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(props.validationSchema),
    defaultValues: props.initialValues,
    shouldUnregister: true,
  });

  const { handleSubmit, reset, watch, setValue } = methods;
  const history = useHistory();
  const location = useLocation();
  const { pk } = useParams();

  let formChainedData = null;
  let formDataAfterUpdateReady = null;
  let watchedValues = {};

  if (chainedData && chainedData[formName]) {
    formChainedData = chainedData[formName].chainedData;
    formDataAfterUpdateReady = chainedData[formName].dataAfterUpdateReady;
  }

  if (watchValues && watchValues.length > 0) {
    watchValues.forEach((value) => {
      watchedValues[value] = watch(value);
    });
  }

  useEffect(() => {
    if (pk) {
      setFormStatus("UPDATE");
      const getUrl = url + pk + "/";
      const axiosOptions = {
        headers: {
          Authorization: "Bearer " + props.access_token,
        },
      };
      axios.get(getUrl, axiosOptions).then((response) => {
        if (linkedData) setFormData(response.data[masterName]);
        else setFormData(response.data);
      });
    } else {
      setFormStatus("CREATE");
    }
  }, [pk, props.access_token, location, url, linkedData, masterName]);

  useEffect(() => {
    if (formData && resetChildChainField) {
      resetDataReadyAfterUpdate({
        formName: formName,
        fields: resetChildChainField,
      });
      Object.keys(chainedFields).forEach((field) => {
        let formDataFieldIsEmptyArray = false;
        if (Array.isArray(formData[field]) && formData[field].length === 0) {
          formDataFieldIsEmptyArray = true;
        }
        if (formData[field] && !formDataFieldIsEmptyArray) {
          if (Array.isArray(formData[field])) {
            let ids = [];
            formData[field].forEach((element) => {
              ids.push(element.id);
            });
            onUpdateChainedData(
              chainedFields[field].app,
              formName,
              chainedFields[field].chainFieldParentMaster,
              ids,
              chainedFields[field].updateChainedField,
              chainedFields[field].chainFieldChildMaster,
              props.access_token
            );
          } else {
            onUpdateChainedData(
              chainedFields[field].app,
              formName,
              chainedFields[field].chainFieldParentMaster,
              formData[field].id,
              chainedFields[field].updateChainedField,
              chainedFields[field].chainFieldChildMaster,
              props.access_token
            );
          }
        } else {
          setDataReadyAfterUpdate(
            formName,
            chainedFields[field].updateChainedField
          );
        }
      });
    }
  }, [
    chainedFields,
    onUpdateChainedData,
    formData,
    props.access_token,
    setDataReadyAfterUpdate,
    resetDataReadyAfterUpdate,
    formName,
    resetChildChainField,
  ]);

  useEffect(() => {
    let ready;
    let readyArr;
    if (chainedFields) {
      readyArr = Object.values(formDataAfterUpdateReady);
      ready = readyArr.reduce((acc, curr) => acc && curr);
    } else ready = true;
    if (formData && ready) {
      const defaultValues = cloneDeep(props.initialValues);
      Object.keys(formData).forEach((field) => {
        if (
          Array.isArray(formData[field]) &&
          formData[field].length !== 0 &&
          formData[field][0] instanceof Object
        ) {
          defaultValues[field] = formData[field].map((i) => {
            return i.id;
          });
        } else if (
          !Array.isArray(formData[field]) &&
          formData[field] instanceof Object
        ) {
          defaultValues[field] = formData[field].id;
        } else {
          defaultValues[field] = formData[field];
        }
      });
      reset(defaultValues);
      setFormData(null);
    }
  }, [
    reset,
    props.initialValues,
    formData,
    props.access_token,
    formDataAfterUpdateReady,
    chainedFields,
  ]);

  const onSubmitHandler = async (values) => {
    const urlUpdate = url + pk + "/";
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    };
    if (formStatus === "UPDATE") {
      if (props.partial) {
        await axios
          .patch(urlUpdate, values, axiosOptions)
          .then((response) => {
            history.push(redirectUrl);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await axios
          .put(urlUpdate, values, axiosOptions)
          .then((response) => {
            history.push(redirectUrl);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      await axios
        .post(url, values, axiosOptions)
        .then((response) => {
          history.push(redirectUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let fields = [];

  Object.keys(fieldMap).forEach((field) => {
    if (
      fieldMap[field].hiddenConditional &&
      !fieldMap[field].condition(watchedValues)
    ) {
      return;
    }

    if (fieldMap[field]["inputType"] === "TextField") {
      fields.push(
        <Grid item xs={12} key={field}>
          <TextField
            name={field}
            label={fieldMap[field]["label"]}
            fullWidth={true}
            {...fieldMap[field]["textFieldProps"]}
          />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "TextAreaField") {
      fields.push(
        <Grid item xs={12} key={field}>
          <TextField
            name={field}
            label={fieldMap[field]["label"]}
            multiline
            rows={fieldMap[field]["rows"]}
            fullWidth={true}
            {...fieldMap[field]["textFieldProps"]}
          />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "IntegerField") {
      fields.push(
        <Grid item xs={12} key={field}>
          <TextField
            name={field}
            label={fieldMap[field]["label"]}
            type="number"
            fullWidth={true}
            {...fieldMap[field]["textFieldProps"]}
          />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "Checkbox") {
      fields.push(
        <Grid item xs={12} key={field}>
          <Checkbox name={field} label={fieldMap[field]["label"]} />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "DateField") {
      fields.push(
        <Grid item xs={12} key={field}>
          <DatePicker
            label={fieldMap[field]["label"]}
            name={field}
            views={fieldMap[field]["views"]}
            disableFuture={fieldMap[field]["disableFuture"]}
            inputFormat={
              fieldMap[field]["inputFormat"]
                ? fieldMap[field]["inputFormat"]
                : "dd/MM/yyyy"
            }
            textFieldProps={{
              ...fieldMap[field]["textFieldProps"],
              fullWidth: true,
            }}
          />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "ImageField") {
      fields.push(
        <Grid item container xs={12} key={field} direction="column">
          <Grid>
            <ImageUpload
              name={field}
              label={fieldMap[field]["label"]}
              showText={!!fieldMap[field]["label"]}
              multiple={fieldMap[field]["multiple"]}
            />
          </Grid>
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "FileField") {
      fields.push(
        <Grid item container xs={12} key={field} direction="column">
          <Grid>
            <FileUpload
              name={field}
              label={fieldMap[field]["label"]}
              showText={!!fieldMap[field]["label"]}
              multiple={fieldMap[field]["multiple"]}
            />
          </Grid>
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "ImageFieldBase64") {
      fields.push(
        <Grid item container xs={12} key={field} direction="column">
          <Grid>
            <img
              style={{ maxHeight: "300px" }}
              src={watchedValues[field]}
              alt={""}
            />
          </Grid>
          <Grid>
            <ImageUploadBase64
              name={field}
              label={fieldMap[field]["label"]}
              showText={!!fieldMap[field]["label"]}
            />
          </Grid>
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "ColorPicker") {
      fields.push(
        <Grid item xs={12} key={field}>
          <ColorPicker
            name={field}
            label={fieldMap[field]["label"]}
            fullWidth={true}
          />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "Autocomplete") {
      let disabled;
      if (fieldMap[field]["hasChainedParent"]) {
        disabled =
          formChainedData &&
          formChainedData[fieldMap[field]["optionsField"]] &&
          formChainedData[fieldMap[field]["optionsField"]].length !== 0 &&
          watchedValues[fieldMap[field]["hasChainedParent"]] !== null
            ? false
            : true;
      }
      let options = [];
      if (
        fieldMap[field]["optionsType"] === "formSupplementaryData" &&
        formSupplementaryData &&
        formSupplementaryData[fieldMap[field]["optionsField"]]
      ) {
        options = formSupplementaryData[fieldMap[field]["optionsField"]];
      } else if (
        fieldMap[field]["optionsType"] === "chainedData" &&
        formChainedData &&
        formChainedData[fieldMap[field]["optionsField"]]
      ) {
        options = formChainedData[fieldMap[field]["optionsField"]];
      }
      fields.push(
        <Grid item xs={12} key={field}>
          <Autocomplete
            name={field}
            multiple={fieldMap[field]["multiple"]}
            options={options}
            disabled={disabled}
            fieldToSave={fieldMap[field]["fieldToSave"]}
            fieldToShow={fieldMap[field]["fieldToShow"]}
            textFieldProps={{
              fullWidth: true,
              ...fieldMap[field]["textFieldProps"],
            }}
          />
        </Grid>
      );
    } else if (fieldMap[field]["inputType"] === "ChainedAutocomplete") {
      let disabled;
      if (fieldMap[field]["hasChainedParent"]) {
        disabled =
          formChainedData &&
          formChainedData[fieldMap[field]["optionsField"]] &&
          formChainedData[fieldMap[field]["optionsField"]].length !== 0 &&
          watchedValues[fieldMap[field]["hasChainedParent"]] !== null
            ? false
            : true;
      }
      let options = [];
      if (
        fieldMap[field]["optionsType"] === "formSupplementaryData" &&
        formSupplementaryData &&
        formSupplementaryData[fieldMap[field]["optionsField"]]
      ) {
        options = formSupplementaryData[fieldMap[field]["optionsField"]];
      } else if (
        fieldMap[field]["optionsType"] === "chainedData" &&
        formChainedData &&
        formChainedData[fieldMap[field]["optionsField"]]
      ) {
        options = formChainedData[fieldMap[field]["optionsField"]];
      }
      fields.push(
        // INCOMPLETE FOR CHAINING MULTIPLE AUTOCOMPLETE
        <Grid item xs={12} key={field}>
          <ChainedAutocomplete
            app={fieldMap[field]["app"]}
            formName={formName}
            chainFieldParentMaster={fieldMap[field]["chainFieldParentMaster"]}
            chainFieldParentValue={
              watchedValues[fieldMap[field]["chainFieldParentName"]]
            }
            updateChainedField={fieldMap[field]["updateChainedField"]}
            chainFieldChildMaster={fieldMap[field]["chainFieldChildMaster"]}
            access_token={props.access_token}
            updateChainedAction={onUpdateChainedData}
            chainFieldChildName={fieldMap[field]["chainFieldChildName"]}
            chainFieldPrevParentValue={
              fieldMap[field]["chainFieldPrevParentValue"]
            }
            setValue={setValue}
            name={field}
            options={options}
            disabled={disabled}
            fieldToSave={fieldMap[field]["fieldToSave"]}
            fieldToShow={fieldMap[field]["fieldToShow"]}
            textFieldProps={{
              fullWidth: true,
              ...fieldMap[field]["textFieldProps"],
            }}
          />
        </Grid>
      );
    } else {
      console.log("UNKNOWN FIELD: ", field);
    }
  });

  return (
    <Container maxWidth="md">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(
            customSubmitHandler ? customSubmitHandler : onSubmitHandler
          )}
        >
          <Grid container spacing={2}>
            {fields}
            <Grid item xs={12}>
              <Button
                startIcon={
                  methods.formState.isSubmitting ? (
                    <Spinner size="1rem" />
                  ) : null
                }
                disabled={methods.formState.isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth={true}
              >
                {methods.formState.isSubmitting ? "Submitting" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    chainedData: state.formData.formsChainedData,
    formSupplementaryData: state.formData.formSupplementaryData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateChainedData: (
      app,
      formName,
      chainFieldParentMaster,
      chainFieldParentValue,
      updateChainedField,
      chainFieldChildMaster,
      access_token,
      chainFieldMultipleChildPrevValue,
      chainFieldChildName,
      setValue
    ) =>
      dispatch(
        actions.updateChainedData(
          app,
          formName,
          chainFieldParentMaster,
          chainFieldParentValue,
          updateChainedField,
          chainFieldChildMaster,
          access_token,
          chainFieldMultipleChildPrevValue,
          chainFieldChildName,
          setValue
        )
      ),
    setDataReadyAfterUpdate: (formName, updateChainedField) =>
      dispatch(actions.setDataReadyAfterUpdate(formName, updateChainedField)),
    resetDataReadyAfterUpdate: (payload) =>
      dispatch(actions.resetDataReadyAfterUpdate(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
