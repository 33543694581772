import { lazy, useEffect, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { StyledEngineProvider, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Auth from "./containers/Auth/Login/Login";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import ChangePassword from "./containers/Auth/ChangePassword/ChangePassword";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";
import ResetPasswordConfirm from "./containers/Auth/ResetPassword/ResetPasswordConfirm";

import Layout from "./containers/Layout/Layout";
import Home from "./components/Home/Home";
import NotFound from "./components/NotFound/NotFound";
import * as actions from "./store/actions/index";
import theme from "./components/UI/Theme/Theme";
import Spinner from "./components/UI/Loading/Spinner";

const Hr = lazy(() => {
  return import("./containers/Modules/HR/HR");
});
const CompanySetup = lazy(() => {
  return import("./containers/Modules/CompanySetup/CompanySetup");
});
const Inventory = lazy(() => {
  return import("./containers/Modules/Inventory/Inventory");
});
const PermissionManager = lazy(() => {
  return import("./containers/Modules/PermissionManager/PermissionManager");
});
const Chatbot = lazy(() => {
  return import("./containers/Modules/Chatbot/Chatbot");
});
const Blog = lazy(() => {
  return import("./containers/Modules/Blog/Blog");
});
const InvoiceTracking = lazy(() => {
  return import("./containers/Modules/InvoiceTracking/InvoiceTracking");
});

const useStyles = makeStyles((theme) => ({
  page: {
    height: "calc(100vh - 84px)",
  },
}));

const App = (props) => {
  const { onTryAutoSignup } = props;
  const classes = useStyles();

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let routes = (
    <Switch>
      <Route path="/" exact component={Auth} />
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route
        path="/reset-password-confirm"
        exact
        component={ResetPasswordConfirm}
      />
      {/* <Route component={NotFound} /> */}
    </Switch>
  );

  if (props.isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/home" exact component={Home} />
        <Route path="/hr" component={Hr} />
        <Route path="/company-setup" component={CompanySetup} />
        <Route path="/inventory" component={Inventory} />
        <Route path="/permission-manager" component={PermissionManager} />
        <Route path="/chatbot" component={Chatbot} />
        <Route path="/blog" component={Blog} />
        <Route path="/invoice-tracking" component={InvoiceTracking} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route component={NotFound} />
      </Switch>
    );
  }

  const loading = (
    <Grid
      className={classes.page}
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <Spinner size={50} />
      </Grid>
    </Grid>
  );

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Layout />
            <Suspense fallback={loading}>{routes}</Suspense>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.access_token != null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
