import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <div
      className="container"
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        padding: "20px",
        backgroundColor: "#f4f4f4",
      }}
    >
      <h1 style={{ color: "#333" }}>Privacy Policy for SIPS</h1>
      <p>
        <strong>Last Updated:</strong> 12/12/2023
      </p>

      <h2 style={{ color: "#333" }}>Introduction</h2>
      <p>
        This Privacy Policy applies to the ERP System application developed and
        operated by Saraf Corporation. Our application offers a range of modules
        for inventory, invoice management, human resources, and fleet
        management, designed to streamline business operations.
      </p>

      <h2 style={{ color: "#333" }}>Data Collection and Usage</h2>
      <p>
        The Saraf Corporation ERP System app collects and stores certain user
        information for authentication and app functionality. This includes:
        <ul>
          <li>Usernames: Used for user authentication purposes.</li>
          <li>
            Chat Data: Interactions with our in-app chatbot are stored to
            enhance user experience and app functionality.
          </li>
          <li>
            Access Tokens: Assigned to each user during their session for
            maintaining session security and user authentication.
          </li>
        </ul>
        This data is stored securely in our in-house database and is essential
        for providing our services effectively.
      </p>

      <h2 style={{ color: "#333" }}>Data Sharing and Disclosure</h2>
      <p>
        We do not share user data such as usernames, chat interactions, and
        access tokens with any third parties. This data is strictly used for the
        functionality of the ERP System app and to improve user experience. All
        data is handled and stored within Saraf Corporation's secure internal
        systems.
      </p>

      <h2 style={{ color: "#333" }}>User Rights</h2>
      <p>
        Users have the right to inquire about their personal data stored in the
        ERP System app. If users have any concerns or requests regarding their
        data, they can reach out to our support team.
      </p>

      <h2 style={{ color: "#333" }}>Data Security</h2>
      <p>
        We are committed to the security and confidentiality of both business
        and personal user data. Our app implements robust security measures to
        protect against unauthorized access, alteration, disclosure, or
        destruction of the data processed by the app.
      </p>

      <h2 style={{ color: "#333" }}>Changes to the Privacy Policy</h2>
      <p>
        This privacy policy is subject to change at the discretion of Saraf
        Corporation. Users will be notified of any significant changes through
        company communication channels or directly within the app.
      </p>

      <h2 style={{ color: "#333" }}>Contact Information</h2>
      <p>
        For any questions or concerns regarding this privacy policy or the ERP
        System app, please contact:
      </p>
      <p>
        <strong>Saraf Corporation Support Team</strong>
        <br />
        Email: info@saraf.ai
      </p>

      <p>
        <strong>Effective Date:</strong> 12/12/2023
      </p>
    </div>
  );
};

export default PrivacyPolicyContent;
