export const breadCrumbsMap = {
  home: "Home",

  //HR
  hr: "HR",
  "permission-manager": "Permission Manager",
  "emp-mast": "Employee Master",
  "emp-mast-add": "Add/Modify Employee",
  "emp-standby-mast": "Employee Standby Master",
  "emp-rejected-mast": "Employee Rejected Master",
  "emp-type-mast": "Employee Type Master",
  "emp-type-mast-add": "Add/Modify Employee Type",
  "emp-status-mast": "Employee Status Master",
  "emp-status-mast-add": "Add/Modify Employee Status",
  "desgn-cat-mast": "Designation Category Master",
  "desgn-cat-mast-add": "Add/Modify Designation Category",
  "desgn-mast": "Designation Master",
  "desgn-mast-add": "Add/Modify Designation",
  "role-mast": "Role Master",
  "role-mast-add": "Add/Modify Role",
  "work-cat-mast": "Work Category Master",
  "work-cat-mast-add": "Add/Modify Work Category",
  "labour-cat-mast": "Labour Category Master",
  "labour-cat-mast-add": "Add/Modify Labour Category",
  "doc-cert-mast": "Documents/Certificate Master",
  "perf-grade-mast": "Performance Grade Master",
  "perf-grade-mast-add": "Add/Modify Performance Grade",
  "task-mast": "Task Master",
  "qualification-mast": "Qualification Master",
  "qualification-mast-add": "Add/Modify Qualification",
  "reference-mast": "Reference Master",
  "reference-mast-add": "Add/Modify Reference",
  "sourcing-mast": "Sourcing Master",
  "sourcing-mast-add": "Add/Modify Sourcing",
  "religion-mast": "Religion Master",
  "religion-mast-add": "Add/Modify Religion",
  "task-trans": "Task Transaction",
  "emp-status-trans": "Employee Status Transaction",
  "perf-grade-trans": "Performance Grade Transaction",
  "emp-type-trans": "Employee Type Transaction",
  "desgn-trans": "Designation Transaction",
  "work-cat-trans": "Work Category Transaction",
  "labour-cat-trans": "Labour Category Transaction",
  "marital-info-trans": "Marital Info Transaction",
  "children-info-trans": "Children Info Transaction",
  "doc-manage-trans": "Documents Management Transaction",
  "emp-unif-trans": "Employee Uniform Transaction",

  //INVENTORY
  inventory: "Inventory",
  "item-mast": "Item Master",
  "item-mast-add": "Add/Modify Item",
  "item-cat-mast": "Item Category Master",
  "item-cat-mast-add": "Add/Modify Item Category",
  "item-unit-mast": "Item Unit Master",
  "item-unit-mast-add": "Add/Modify Item Unit",
  "item-group-mast": "Item Group Master",
  "item-group-mast-add": "Add/Modify Item Group",
  "std-mast": "Standard Master",
  "vendor-mast": "Vendor Master",
  "vendor-mast-add": "Add/Modify Vendor",
  "closing-stock-trans": "Closing Stock Transaction",
  "delivery-trans": "Delivery Transaction",
  consumption: "Consumption Report",

  //COMPANY-SETUP
  "company-setup": "Company Setup",
  "client-mast": "Client Master",
  "client-mast-add": "Add/Modify Client",
  "contract-mast": "Contract Master",
  "contract-mast-add": "Add/Modify Contract",
  "installation-mast": "Installation Master",
  "installation-mast-add": "Add/Modify Installation",
  "country-mast": "Country Master",
  "state-mast": "State Master",
  "city-mast": "City Master",
  "factory-mast": "Factory Master",
  "factory-mast-add": "Add/Modify Factory",
  "office-mast": "Office Master",
  "office-mast-add": "Add/Modify Office",
  "warehouse-mast": "Warehouse Master",
  "warehouse-mast-add": "Add/Modify Warehouse",
  "currency-mast": "Currency Master",
  "asset-mast": "Asset Mast",
  "asset-mast-add": "Add/Modify Asset Mast",
  "asset-type-mast": "Asset Type Mast",
  "asset-type-mast-add": "Add/Modify Asset Type Mast",
  "asset-subtype-mast": "Asset Subtype Mast",
  "asset-subtype-mast-add": "Add/Modify Asset Subtype Mast",

  //CHATBOT
  chatbot: "Chatbot",
  "chat-history": "Chat History",

  //INVOICE TRACKING
  "invoice-tracking": "Invoice Tracking",
  "invoice-mast-add": "Add/Modify Invoice",
  "invoice-category-mast": "Invoice Category Master",
  "invoice-category-mast-add": "Add/Modify Invoice Category",
  "invoice-asset-mast": "Invoice Asset Master",
  "invoice-asset-mast-add": "Add/Modify Invoice Asset",
  "invoice-deduction-mast": "Invoice Deduction Master",
  "invoice-deduction-mast-add": "Add/Modify Invoice Deduction",
  "invoice-to-submit": "Invoice To Submit",
  "invoice-to-submit-add": "Add/Modify Invoice To Submit",
  "invoice-reconciliation-trans": "Invoice Reconciliation",
  "invoice-trace": "Invoice Trace",

  //BLOG
  blog: "Blog",
  "blog-view": "Blog Post View",
  "blog-add": "Add/Modify Blog Post",
  "blog-category-mast": "Blog Category Master",
  "blog-category-mast-add": "Add/Modify Blog Category",

  //AUTH
  "change-password": "Change Password",

  //PRIVACY POLICY
  "privacy-policy": "Privacy Policy",

  //PERMISSION MANAGER
};
