const NavbarItemSetter = (module, permission) => {
  const NavItemData = {
    hr: [
      {
        name: "Master",
        to: "",
        show:
          permission.emp_mast ||
          permission.emp_type_mast ||
          permission.emp_standby_mast ||
          permission.emp_rejected_mast ||
          permission.desgn_cat_mast ||
          permission.desgn_mast ||
          permission.role_mast ||
          permission.work_cat_mast ||
          permission.labour_cat_mast ||
          permission.deduction_mast ||
          permission.doc_cert_mast ||
          permission.perf_grade_mast ||
          permission.task_mast ||
          permission.emp_unif_meas_mast ||
          permission.perf_bonus_mast ||
          permission.rejoin_bonus_mast ||
          permission.qualification_mast ||
          permission.reference_mast ||
          permission.sourcing_mast ||
          permission.religion_mast
            ? true
            : false,
        item_suboptions: [
          {
            name: "Crew Detail Master",
            to: "",
            show:
              permission.emp_mast ||
              permission.emp_type_mast ||
              permission.emp_standby_mast ||
              permission.emp_rejected_mast ||
              permission.desgn_cat_mast ||
              permission.desgn_mast ||
              permission.role_mast ||
              permission.work_cat_mast ||
              permission.labour_cat_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Employee Master",
                to: "/hr/emp-mast",
                show: permission.emp_mast ? true : false,
              },
              {
                name: "Employee Standby",
                to: "/hr/emp-standby-mast",
                show: permission.emp_standby_mast ? true : false,
              },
              {
                name: "Employee Rejected",
                to: "/hr/emp-rejected-mast",
                show: permission.emp_rejected_mast ? true : false,
              },
              {
                name: "Employee Type",
                to: "/hr/emp-type-mast",
                show: permission.emp_type_mast ? true : false,
              },
              {
                name: "Employee Status",
                to: "/hr/emp-status-mast",
                show: permission.emp_status_mast ? true : false,
              },
              {
                name: "Designation Category",
                to: "/hr/desgn-cat-mast",
                show: permission.desgn_cat_mast ? true : false,
              },
              {
                name: "Designation",
                to: "/hr/desgn-mast",
                show: permission.desgn_mast ? true : false,
              },
              {
                name: "Role",
                to: "/hr/role-mast",
                show: permission.role_mast ? true : false,
              },
              {
                name: "Work Category",
                to: "/hr/work-cat-mast",
                show: permission.work_cat_mast ? true : false,
              },
              {
                name: "Labour Category",
                to: "/hr/labour-cat-mast",
                show: permission.labour_cat_mast ? true : false,
              },
            ],
          },
          {
            name: "Deduction",
            to: "",
            show: permission.deduction_mast ? true : false,
          },
          {
            name: "Documents / Cert",
            to: "/hr/doc-cert-mast",
            show: permission.doc_cert_mast ? true : false,
          },
          {
            name: "Performace / Grade",
            to: "/hr/perf-grade-mast",
            show: permission.perf_grade_mast ? true : false,
          },
          {
            name: "Task Management",
            to: "/hr/task-mast",
            show: permission.task_mast ? true : false,
          },
          {
            name: "Staff Kits",
            to: "",
            show: permission.emp_unif_meas_mast ? true : false,
          },
          {
            name: "Performance Bonus",
            to: "",
            show: permission.perf_bonus_mast ? true : false,
          },
          {
            name: "Re-Join Bonus",
            to: "",
            show: permission.rejoin_bonus_mast ? true : false,
          },
          {
            name: "Misc",
            to: "",
            show:
              permission.qualification_mast ||
              permission.reference_mast ||
              permission.sourcing_mast ||
              permission.religion_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Qualification",
                to: "/hr/qualification-mast",
                show: permission.qualification_mast ? true : false,
              },
              {
                name: "Reference",
                to: "/hr/reference-mast",
                show: permission.reference_mast ? true : false,
              },
              {
                name: "Sourcing",
                to: "/hr/sourcing-mast",
                show: permission.sourcing_mast ? true : false,
              },
              {
                name: "Religion",
                to: "/hr/religion-mast",
                show: permission.religion_mast ? true : false,
              },
            ],
          },
        ],
      },
      {
        name: "TRANSACTION",
        to: "",
        show:
          permission.emp_status_trans ||
          permission.emp_type_trans ||
          permission.desgn_trans ||
          permission.work_cat_trans ||
          permission.labour_cat_trans ||
          permission.marital_info_trans ||
          permission.children_info_trans ||
          permission.crew_move_trans ||
          permission.crew_plan_trans ||
          permission.task_list_trans ||
          permission.perf_grade_trans ||
          permission.doc_manage_trans ||
          permission.ned_id_trans ||
          permission.wage_calc_trans ||
          permission.office_sal_trans ||
          permission.site_sal_trans ||
          permission.temp_sal_trans ||
          permission.stip_trainee_sal_trans ||
          permission.sal_inc_trans ||
          permission.loan_trans ||
          permission.insurance_trans ||
          permission.emp_unif_meas_trans
            ? true
            : false,
        item_suboptions: [
          {
            name: "Crew Details Transaction",
            to: "",
            show:
              permission.emp_status_trans ||
              permission.emp_type_trans ||
              permission.desgn_trans ||
              permission.work_cat_trans ||
              permission.labour_cat_trans ||
              permission.marital_info_trans ||
              permission.children_info_trans
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Employee Status",
                to: "/hr/emp-status-trans",
                show: permission.emp_status_trans ? true : false,
              },
              {
                name: "Employee Type",
                to: "/hr/emp-type-trans",
                show: permission.emp_type_trans ? true : false,
              },
              {
                name: "Designation",
                to: "/hr/desgn-trans",
                show: permission.desgn_trans ? true : false,
              },
              {
                name: "Work Category",
                to: "/hr/work-cat-trans",
                show: permission.work_cat_trans ? true : false,
              },
              {
                name: "Labour Category",
                to: "/hr/labour-cat-trans",
                show: permission.labour_cat_trans ? true : false,
              },
              {
                name: "Marital Info",
                to: "/hr/marital-info-trans",
                show:
                  permission.marital_info_trans ||
                  permission.children_info_trans
                    ? true
                    : false,
                drop_suboptions: [
                  {
                    name: "Spouse",
                    to: "/hr/marital-info-trans",
                    show: permission.marital_info_trans ? true : false,
                  },
                  {
                    name: "Children",
                    to: "/hr/children-info-trans",
                    show: permission.children_info_trans ? true : false,
                  },
                ],
              },
            ],
          },
          {
            name: "Crew Movement",
            to: "",
            show: permission.crew_move_trans ? true : false,
          },
          {
            name: "Crew Planning",
            to: "",
            show: permission.crew_plan_trans ? true : false,
          },
          {
            name: "Performance Management",
            to: "",
            show:
              permission.task_list_trans || permission.perf_grade_trans
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Task List",
                to: "/hr/task-trans",
                show: permission.task_list_trans ? true : false,
              },
              {
                name: "Performance Grade",
                to: "/hr/perf-grade-trans",
                show: permission.perf_grade_trans ? true : false,
              },
            ],
          },
          {
            name: "Documents",
            to: "",
            show:
              permission.doc_manage_trans || permission.ned_id_trans
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Manage Documents",
                to: "/hr/doc-manage-trans",
                show: permission.doc_manage_trans ? true : false,
              },
              {
                name: "NED ID",
                to: "",
                show: permission.ned_id_trans ? true : false,
              },
            ],
          },
          {
            name: "Salary & Wage",
            to: "",
            show:
              permission.wage_calc_trans ||
              permission.office_sal_trans ||
              permission.site_sal_trans ||
              permission.temp_sal_trans ||
              permission.stip_trainee_sal_trans
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Wage Calculation",
                to: "",
                show: permission.wage_calc_trans ? true : false,
              },
              {
                name: "Office Salary",
                to: "",
                show: permission.office_sal_trans ? true : false,
              },
              {
                name: "Site Salary",
                to: "",
                show: permission.site_sal_trans ? true : false,
              },
              {
                name: "Temporary",
                to: "",
                show: permission.temp_sal_trans ? true : false,
              },
              {
                name: "Stipend & Trainee",
                to: "",
                show: permission.stip_trainee_sal_trans ? true : false,
              },
            ],
          },
          {
            name: "Salary Increments",
            to: "",
            show: permission.sal_inc_trans ? true : false,
          },
          {
            name: "Loan",
            to: "",
            show: permission.loan_trans ? true : false,
          },
          {
            name: "Insurance",
            to: "",
            show: permission.insurance_trans ? true : false,
          },
          {
            name: "Staff Kits",
            to: "",
            show: permission.emp_unif_meas_trans ? true : false,
            drop_suboptions: [
              {
                name: "Uniforms / Shoes Size",
                to: "/hr/emp-unif-trans",
                show: permission.emp_unif_meas_trans ? true : false,
              },
            ],
          },
        ],
      },
    ],
    company_setup: [
      {
        name: "Setup",
        to: "",
        show:
          true ||
          permission.country_mast ||
          permission.state_mast ||
          permission.city_mast ||
          permission.client_mast ||
          permission.contract_mast ||
          permission.installation_mast ||
          permission.warehouse_mast ||
          permission.factory_mast ||
          permission.office_mast ||
          permission.currency_mast ||
          permission.module_mast
            ? true
            : false,
        item_suboptions: [
          {
            name: "Company Details",
            to: "",
            show: true ? true : false,
            drop_suboptions: [
              {
                name: "General Info",
                to: "/company-setup/general-info",
                show: true ? true : false,
              },
              {
                name: "Company Bank",
                to: "/company-setup/company-bank",
                show: true ? true : false,
              },
              {
                name: "Departments",
                to: "/company-setup/departments",
                show: true ? true : false,
              },
            ],
          },
          {
            name: "Statutory Details",
            to: "",
            show: true ? true : false,
            drop_suboptions: [
              {
                name: "Professional Tax",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Provident Fund",
                to: "",
                show: true ? true : false,
              },
              {
                name: "ESIC",
                to: "",
                show: true ? true : false,
              },
              {
                name: "MLW",
                to: "",
                show: true ? true : false,
              },
              {
                name: "TAN",
                to: "",
                show: true ? true : false,
              },
              {
                name: "PAN",
                to: "",
                show: true ? true : false,
              },
              {
                name: "GST",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Gratuity",
                to: "",
                show: true ? true : false,
              },
            ],
          },
          {
            name: "Bank",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Currency",
            to: "/company-setup/currency-mast",
            show: permission.currency_mast ? true : false,
          },
          {
            name: "Rules & Policy",
            to: "",
            show: true ? true : false,
            drop_suboptions: [
              {
                name: "Increment",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Loan",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Reimbursement",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Bonus",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Leave",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Salary Pay Type",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Attendance",
                to: "",
                show: true ? true : false,
              },
            ],
          },
          {
            name: "Clients & Contracts",
            to: "",
            show:
              true ||
              permission.client_mast ||
              permission.contract_mast ||
              permission.istallation_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Client",
                to: "/company-setup/client-mast",
                show: permission.client_mast ? true : false,
              },
              {
                name: "Contract",
                to: "/company-setup/contract-mast",
                show: permission.contract_mast ? true : false,
              },
              {
                name: "Installation / Sites",
                to: "/company-setup/installation-mast",
                show: permission.installation_mast ? true : false,
              },
              {
                name: "Line Items & Prices",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Invoice",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Invoice Modify",
                to: "",
                show: true ? true : false,
              },
            ],
          },
          {
            name: "Area & Locations",
            to: "",
            show:
              permission.country_mast ||
              permission.state_mast ||
              permission.city_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Country",
                to: "/company-setup/country-mast",
                show: permission.country_mast ? true : false,
              },
              {
                name: "State",
                to: "/company-setup/state-mast",
                show: permission.state_mast ? true : false,
              },
              {
                name: "City",
                to: "/company-setup/city-mast",
                show: permission.city_mast ? true : false,
              },
            ],
          },
          {
            name: "Company Sites",
            to: "",
            show:
              permission.office_mast ||
              permission.factory_mast ||
              permission.warehouse_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Office",
                to: "/company-setup/office-mast",
                show: permission.office_mast ? true : false,
              },
              {
                name: "Factory",
                to: "/company-setup/factory-mast",
                show: permission.factory_mast ? true : false,
              },
              {
                name: "Warehouse",
                to: "/company-setup/warehouse-mast",
                show: permission.warehouse_mast ? true : false,
              },
            ],
          },
          {
            name: "Assets",
            to: "",
            show:
              permission.asset_type_mast ||
              permission.asset_subtype_mast ||
              permission.asset_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Asset Type Master",
                to: "/company-setup/asset-type-mast",
                show: permission.asset_type_mast ? true : false,
              },
              {
                name: "Asset Subtype Master",
                to: "/company-setup/asset-subtype-mast",
                show: permission.asset_subtype_mast ? true : false,
              },
              {
                name: "Asset Master",
                to: "/company-setup/asset-mast",
                show: permission.asset_mast ? true : false,
              },
            ],
          },
          {
            name: "Module Master",
            to: "",
            show: permission.module_mast ? true : false,
          },
          {
            name: "Action Master",
            to: "",
            show: true ? true : false,
          },
          {
            name: "User Master",
            to: "",
            show: true ? true : false,
          },
          {
            name: "User Permissions",
            to: "",
            show: true ? true : false,
          },
        ],
      },
    ],
    inventory: [
      {
        name: "Master",
        to: "",
        show: true ? true : false,
        item_suboptions: [
          {
            name: "Item",
            to: "",
            show:
              permission.item_mast ||
              permission.item_cat_mast ||
              permission.item_unit_mast ||
              permission.item_group_mast ||
              permission.std_mast
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Item Master",
                to: "/inventory/item-mast",
                show: permission.item_mast ? true : false,
              },
              {
                name: "Item Category Master",
                to: "/inventory/item-cat-mast",
                show: permission.item_cat_mast ? true : false,
              },
              {
                name: "Item Unit Master",
                to: "/inventory/item-unit-mast",
                show: permission.item_unit_mast ? true : false,
              },
              {
                name: "Item Group Master",
                to: "/inventory/item-group-mast",
                show: permission.item_group_mast ? true : false,
              },
            ],
          },
          {
            name: "Standard Quantity",
            to: "/inventory/std-mast",
            show: permission.std_mast ? true : false,
          },
          {
            name: "Vendor Master",
            to: "/inventory/vendor-mast",
            show: permission.vendor_mast ? true : false,
          },
          {
            name: "Bill of Material",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Remote Site",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Godown",
            to: "inventory/godown-master",
            show: permission.godown_mast ? true : false,
          },
          {
            name: "Category",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Party Type",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Warehouse Type",
            to: "",
            show: true ? true : false,
          },
          {
            name: "HSN",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Staff Credit",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Non Raw Material",
            to: "",
            show: true ? true : false,
            drop_suboptions: [
              {
                name: "M/W Empty Btl",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Thermocol Box",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Plastic Crates",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Empty Cartoons",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Empty Baskets",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Empty Bags",
                to: "",
                show: true ? true : false,
              },
            ],
          },
        ],
      },
      {
        name: "Transaction",
        to: "",
        show:
          (permission.closing_stock_list_trans &&
            permission.closing_stock_detail_trans) ||
          (permission.delivery_list_trans && permission.delivery_detail_trans)
            ? true
            : false,
        item_suboptions: [
          {
            name: "Meals",
            to: "",
            show: true ? true : false,
            drop_suboptions: [
              {
                name: "Meal Input",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Meal Verification",
                to: "",
                show: true ? true : false,
              },
            ],
          },
          {
            name: "Stock",
            to: "",
            show:
              permission.closing_stock_list_trans &&
              permission.closing_stock_detail_trans
                ? true
                : false,
            drop_suboptions: [
              {
                name: "Excess Stock",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Actual Stock",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Stock Transfer",
                to: "",
                show: true ? true : false,
              },
              {
                name: "Closing Stock",
                to: "/inventory/closing-stock-trans",
                show:
                  permission.closing_stock_list_trans &&
                  permission.closing_stock_detail_trans
                    ? true
                    : false,
              },
            ],
          },
          {
            name: "Godown Receipt",
            to: "",
            show: true ? true : false,
          },

          {
            name: "Grouping",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Spoilage at Godown",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Misc Issue ",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Delivery",
            to: "/inventory/delivery-trans",
            show:
              permission.delivery_list_trans && permission.delivery_detail_trans
                ? true
                : false,
          },
          {
            name: "Manager Confirmation",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Spoilage at Inst",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Return to Godown",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Sanction",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Stock Scrutiny",
            to: "",
            show: true ? true : false,
          },
          {
            name: "Rate Management",
            to: "",
            show: true ? true : false,
          },
        ],
      },
      {
        name: "Reports",
        to: "",
        show:
          permission.closing_stock_list_trans &&
          permission.closing_stock_detail_trans &&
          permission.delivery_list_trans &&
          permission.delivery_detail_trans
            ? true
            : false,
        item_suboptions: [
          {
            name: "Consumption",
            to: "/inventory/consumption",
            show:
              permission.closing_stock_list_trans &&
              permission.closing_stock_detail_trans &&
              permission.delivery_list_trans &&
              permission.delivery_detail_trans
                ? true
                : false,
          },
        ],
      },
    ],
    oauth2: [{ name: "Menu", to: "", show: true ? true : false }],
    chatbot: [
      {
        name: "Menu",
        to: "",
        show: true ? true : false,
        item_suboptions: [
          {
            name: "Chat History",
            to: "/chatbot/chat-history",
            show: permission.chat_history ? true : false,
          },
        ],
      },
    ],
    invoice_tracking: [
      {
        name: "Master",
        to: "",
        show:
          permission.invoice_asset_mast || permission.invoice_category_mast
            ? true
            : false,
        item_suboptions: [
          {
            name: "Invoice Asset Master",
            to: "/invoice-tracking/invoice-asset-mast/",
            show: permission.invoice_asset_mast ? true : false,
          },
          {
            name: "Invoice Category Master",
            to: "/invoice-tracking/invoice-category-mast/",
            show: permission.invoice_category_mast ? true : false,
          },
          {
            name: "Invoice Deduction Master",
            to: "/invoice-tracking/invoice-deduction-mast/",
            show: permission.invoice_deduction_mast ? true : false,
          },
        ],
      },
      {
        name: "Transaction",
        to: "",
        show: permission.invoice_to_submit ? true : false,
        item_suboptions: [
          {
            name: "Invoice To Submit",
            to: "/invoice-tracking/invoice-to-submit/",
            show: permission.invoice_to_submit ? true : false,
          },
          {
            name: "Invoice Reconciliation",
            to: "/invoice-tracking/invoice-reconciliation-trans/",
            show: permission.invoice_reconciliation_trans ? true : false,
          },
        ],
      },
      {
        name: "Reports",
        to: "",
        show:
          permission.invoice_mast && permission.invoice_trans ? true : false,
        item_suboptions: [
          {
            name: "Invoice Trace",
            to: "/invoice-tracking/invoice-trace/",
            show:
              permission.invoice_mast && permission.invoice_trans
                ? true
                : false,
          },
        ],
      },
    ],
    blog: [
      {
        name: "Menu",
        to: "",
        show: permission.blog_category_mast,
        item_suboptions: [
          {
            name: "Blog Category Master",
            to: "/blog/blog-category-mast/",
            show: permission.blog_category_mast,
          },
        ],
      },
    ],
  };

  return NavItemData[module];
};

export default NavbarItemSetter;
